/* eslint-disable jsx-a11y/img-redundant-alt */
// React Imports
import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import components
import Hero from "../../components/Hero";
import PurpleBanner from "../../components/PurpleBanner";
import AnchorScroll from "../../components/AnchorScroll";
import Quote from "../../components/Quote/Quote";

// Library Import
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Images
import ill_carousel_1 from "../../images/ill_carousel_1.png";
import ill_carousel_2 from "../../images/ill_carousel_2.png";
import ill_carousel_3 from "../../images/ill_carousel_3.png";
import ill_carousel_4 from "../../images/ill_carousel_4.png";
import one_in_30 from "../../images/IMG_1_in_30.jpg";
import handQuotation from "../../images/img_CRC_Quote_Hand.jpg";
// import handQuotation from "../../images/img_CRC_Quote_Hand.png";

export default function CAboutKRASG12C() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("aboutKRASG12C", "crc");

    addAnimation();
    setTimeout(() => {
      stopPulse();
    }, 100);
  }, []);

  function addAnimation() {
    const slide = document.querySelector(".slick-track");
    slide.setAttribute("data-aos", "slide-animation");
  }

  // Custom Slick Arrows
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  // Slick Slider Settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function stopPulse() {
    const prevArrow = document.querySelector(".slick-arrow.slick-prev");
    const nextArrow = document.querySelector(".slick-arrow.slick-next");

    nextArrow.addEventListener("click", () => {
      setTimeout(() => {
        nextArrow.classList.add("no-pulse");
      }, 100);
    });
    nextArrow.addEventListener("touchstart", () => {
      setTimeout(() => {
        nextArrow.classList.add("no-pulse");
      }, 100);
    });
    prevArrow.addEventListener("click", () => {
      setTimeout(() => {
        nextArrow.classList.add("no-pulse");
      }, 100);
    });
    prevArrow.addEventListener("touchstart", () => {
      setTimeout(() => {
        nextArrow.classList.add("no-pulse");
      }, 100);
    });
  }

  const [handleOpenModal] = useOutletContext();

  return (
    <>
      <Helmet>
        <title>Understanding Biomarkers and Testing</title>
        <meta
          name="description"
          content="Learn more about biomarkers and mutations, their role in advanced disease, and significance in treatment decisions."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="About"
        subtitle={
          <>
            <em>KRAS&nbsp;G12C</em>
          </>
        }
      />
      <section>
        <h2 className="rotate-left">
          ABOUT <em>KRAS&nbsp;G12C</em> IN CRC
        </h2>
        <h3>Cell mutations can lead to the development of CRC.</h3>
        <p>
          Cancer that starts in the tissues of the colon is called colon cancer,
          and cancer that starts in the rectum is called rectal cancer. Cancer
          that affects either of these parts may also be called colorectal
          cancer, or CRC for short.
          <br />
          <br />
          People with cancer can have changes to one or more genes in a cell
          that cause it to not work properly. These mutations lead to abnormal
          growth of cells.
          <br />
          <br />
          The growth and development of cells can be controlled by genes like{" "}
          <em>KRAS</em>. When <em>KRAS</em> is mutated, this can set off a
          domino effect that can lead to cancer. The <em>KRAS G12C</em> mutation
          is one of many mutations that may be found in CRC.
        </p>
      </section>
      <section>
        <h3>
          <em>KRAS</em> mutations are among the most common mutations found in
          CRC, and about 1 in 30 people with CRC have the <em>KRAS G12C</em>{" "}
          mutation.
        </h3>
        <img src={one_in_30} alt="Illustration describing common mutations" />
      </section>
      <section className="slick-container no-max-width">
        <h3 className="slider-header">KRAS works like an on/off switch.</h3>
        <p className="click-to-learn-more">
          Click the purple arrows to learn more.
        </p>
        <Slider {...settings}>
          <div>
            <img
              src={ill_carousel_1}
              alt="A carousel of images describing how the growth and development of cells works like an on/off switch"
            />
            <p>
              <em>KRAS</em> is a gene that makes a&nbsp;protein
            </p>
          </div>
          <div>
            <img src={ill_carousel_2} alt="ill_carousel_2" />
            <p>This protein works like an on/off&nbsp;switch</p>
          </div>
          <div>
            <img src={ill_carousel_3} alt="ill_carousel_3" />
            <p>
              <em>KRAS&nbsp;G12C</em> can make the switch stay&nbsp;“on”
            </p>
          </div>
          <div>
            <img src={ill_carousel_4} alt="ill_carousel_4" />
            <p className="wider-caption">
              This can cause cancer cells to grow and spread (metastasize)
              through the&nbsp;body
            </p>
          </div>
        </Slider>
      </section>
      <section id="biomarker-testing" className="testing pr">
        <h2 className="rotate-left">About Biomarker testing</h2>
        <h3>
          Your cancer may have biomarkers that can be identified
          through&nbsp;testing.
        </h3>
        <p>
          Biomarker testing is a way to look for genes and proteins that can
          provide information about cancer. Each person’s cancer has a unique
          pattern of biomarkers. Knowing your mutation status can help your
          doctor find the best treatment approach for you.
          <br />
          <br />
          Ask your doctor to test for <em>KRAS G12C</em> to determine your
          mutation status. Your doctor may have already tested you when you were
          first diagnosed and can look back to check for <em>KRAS G12C</em>.
        </p>
        <a
          href="https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment"
          target="_blank"
          rel="noreferrer"
          className="button purple"
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal(
              "https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment"
            );
          }}
        >
          Learn more about biomarker testing
        </a>
      </section>
      <Quote
        text="Get an understanding of your biomarker mutations and use that to make sure you find the best possible treatment approach."
        author="KRAZATI TRIAL PARTICIPANT"
        image={handQuotation}
        alt="An arm is holding up a yellow quote mark to indicate a patient testimonial"
      />
      <section className="footnote no-max-width">
        <p className="footnotes">CRC=colorectal cancer. </p>
      </section>
      <PurpleBanner
        text="KRAZATI is designed to suppress KRAS G12C tumor growth."
        linkText="LEARN HOW KRAZATI WORKS"
        link="/c/about-krazati"
      />
    </>
  );
}
