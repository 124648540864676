// React Imports
import React, { useEffect } from 'react';
// import { useOutletContext } from "react-router-dom";
import { Link } from 'react-router-dom';

// Import Components
import Hero from '../../components/Hero';
import { Helmet } from 'react-helmet';
import AnchorScroll from '../../components/AnchorScroll';

// Import Images
import thirtyFourPercent from '../../images/IMG_34_percent.jpg';
import sixMonths from '../../images/IMG_5.8_months.jpg';
import chartWithHandM from '../../images/common-side-effects-graph-m.jpg';
import chartWithHand from '../../images/common-side-effects-graph.jpg';

export default function CClinicalTrialResults() {
  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('study-results', 'crc');
  }, []);

  return (
    <>
      <Helmet>
        <title>KRAZATI&reg; | Efficacy and Safety Review</title>
        <meta
          name="description"
          content="Review the efficacy and safety of Krazati&reg; (adagrasib) + cetuximab through our clinical trial results."
        />
      </Helmet>
      <AnchorScroll />
      <Hero title="Clinical" subtitle="Trial Results" />
      <section>
        <h3>
          KRAZATI was tested in combination with cetuximab in a clinical trial
          of 94 adults with <em>KRAS G12C</em>-positive advanced CRC who
          previously received certain chemotherapy medicines.
        </h3>
        <p>
          The trial evaluated how many people responded to KRAZATI + cetuximab
          and how long their tumors responded to treatment.
        </p>
      </section>
      <section className="flex-container img-right">
        <div className="flex-item">
          <h3 className="mobile-only">
            34% saw tumors shrink. <span>(Objective response rate)</span>
          </h3>
          <img
            src={thirtyFourPercent}
            alt="Percent meter representing the ORR data in clinical trial results"
          />
        </div>
        <div className="flex-item">
          <h3 className="desktop-only">
            34% saw tumors shrink.
            <br />
            <span>(Objective response rate)</span>
          </h3>
          <p>
            Based on 94 people, 32 people saw a decrease in tumor size. No
            person saw their tumors disappear completely.
          </p>
        </div>
      </section>
      <section className="flex-container img-left">
        <div className="flex-item">
          <h3 className="mobile-only">
            How long did the tumors respond to treatment?{' '}
            <span>(Duration of response)</span>
          </h3>
          <img
            src={sixMonths}
            alt="Chart representing the median duration of response to treatment in KRAZATI - adagrasib clinical trial results"
          />
        </div>
        <div className="flex-item">
          <h3 className="desktop-only">
            How long did the tumors respond to treatment?{' '}
            <span>(Duration of response)</span>
          </h3>
          <p>
            Of those people (n=32) whose tumors responded to treatment, the
            median duration of response was 5.8 months.
          </p>
        </div>
      </section>

      <section className="purple-banner thin">
        <div className="content-wrapper">
          <p>
            Experiences with KRAZATI vary; speak with your doctor for more
            information.
          </p>
        </div>
      </section>
      <section className="side-effects">
        <h3>Possible side effects</h3>
        <p className="mbp">
          KRAZATI can cause serious side effects, including stomach and
          intestinal (gastrointestinal) problems, changes in the electrical
          activity of your heart called QTc prolongation, liver problems, and
          lung or breathing problems.
        </p>
        <p className="mbp blue">
          <strong>
            Listen to your body, listen to yourself. If you experience any side
            effects, it’s important to talk to your healthcare provider right
            away.
          </strong>
        </p>
        <p>
          <strong>Stomach and intestinal (gastrointestinal) problems</strong>
        </p>
        <p className="mbp">
          Stomach and intestinal side effects, including nausea, diarrhea, or
          vomiting, are common with KRAZATI but can also sometimes be severe.
          KRAZATI can also cause serious stomach and intestinal side effects
          such as bleeding, obstruction, inflammation of the colon (colitis),
          and narrowing (stenosis).
        </p>
        <p className="mbp">
          <strong>
            Call your healthcare provider if you develop any of the signs or
            symptoms of stomach or intestinal problems listed above during
            treatment with KRAZATI.
          </strong>
        </p>
        <p className="mbp">
          Your healthcare provider may prescribe an antidiarrheal medicine or
          anti-nausea medicine, or other treatment, as needed.
        </p>
        <p>
          <strong>
            Changes in the electrical activity of your heart (called QTc
            prolongation)
          </strong>
          <br></br>
          Certain changes can occur in the electrical activity of your heart
          during treatment with KRAZATI and can be seen on a test called an
          electrocardiogram (ECG or EKG). QTc prolongation can increase your
          risk for irregular heartbeats that can be life-threatening, such as
          torsades de pointes, and can lead to sudden death.
        </p>
        <ul>
          <li>
            You should not take KRAZATI if you have congenital long QT syndrome
            or if you currently have QTc prolongation
          </li>
          <ul>
            <li>
              Your healthcare provider should monitor the electrical activity of
              your heart and the levels of body salts in your blood
              (electrolytes) especially potassium and magnesium before starting
              and during treatment with KRAZATI if you have heart failure, a
              slow heart rate, abnormal levels of electrolytes in your blood, or
              if you take a medicine that can prolong the QT interval of your
              heartbeat
            </li>
            <li>
              <strong>
                Tell your healthcare provider if you feel dizzy, lightheaded, or
                faint, or if you get abnormal heartbeats during treatment with
                KRAZATI
              </strong>
            </li>
          </ul>
        </ul>
        <br />
        <p>
          <strong>Liver problems</strong>
        </p>
        <p>
          Abnormal liver blood test results are common with KRAZATI and can
          sometimes be severe. Your healthcare provider should do blood tests
          before starting and during treatment with KRAZATI to check your liver
          function. Tell your healthcare provider right away if you develop any
          signs or symptoms of liver problems, including:
        </p>
        <ul>
          <li>
            Your skin or the white part of your eyes turns yellow (jaundice)
          </li>
          <li>Dark or “tea-colored” urine</li>
          <li>Light-colored stools (bowel movements)</li>
          <li>Tiredness or weakness</li>
          <li>Nausea or vomiting</li>
          <li>Bleeding or bruising</li>
          <li>Loss of appetite</li>
          <li>
            Pain, aching, or tenderness on the right side of your stomach area
            (abdomen)
          </li>
        </ul>
      </section>
      <section className="hand-chart-section">
        <p>
          <strong>Lung or breathing problems</strong>
        </p>
        <p className="mbp">
          KRAZATI may cause inflammation of the lungs that can lead to death.
          Tell your healthcare provider or get emergency medical help right away
          if you have new or worsening shortness of breath, cough, or fever.
        </p>

        <p className="mbp">
          <span className="blue">
            The most common side effects of KRAZATI when used in combination
            with cetuximab for CRC include:
          </span>
          <br></br>
          skin rash, nausea, diarrhea, vomiting, tiredness, muscle and bone
          pain, headache, dry skin, stomach pain, decreased appetite, swelling,
          low red blood cell count, cough, dizziness, constipation, nerve damage
          in the arms and legs.
        </p>
        <p className="mbp">
          Certain abnormal blood test results are common during treatment with KRAZATI, when used
          alone or in combination with cetuximab. Your healthcare provider will
          monitor you for abnormal blood tests and treat you if needed.
        </p>
        <p className="mbp">
          KRAZATI may cause fertility problems in males and females, which may
          affect your ability to have children. Talk to your healthcare provider
          if this is a concern for you.
        </p>
        <h3 className="mtp">
          The most common side effects (≥20%) of combination treatment with
          KRAZATI&nbsp;+&nbsp;cetuximab&nbsp;(N=94)
        </h3>
      </section>
      <div className="side-effects-chart-container">
        <img
          className="chart hand mobile-only"
          src={chartWithHandM}
          alt="Man's arm holding chart representing the most common side effects"
        />
        <img
          className="chart hand desktop-only"
          src={chartWithHand}
          alt="Man's arm holding chart representing the most common side effects"
        />
      </div>
      <section>
        <p className="blue mb-20">
          <strong>
            For managing side effects that can result from cetuximab, such as
            skin rashes, please talk to your doctor.
          </strong>
        </p>
        <p className="lh-md-reduced">
          <strong>
            These are not all the possible side effects of KRAZATI. Call your
            doctor for medical advice about side effects. You may report side
            effects to the FDA at{' '}
            <a href="tel:+18003321088" className="black-sm">
              <span className="no-wrap bold-sm">1-800-FDA-1088</span>
            </a>
            . You may also report side effects at{' '}
            <a href="tel:+18446472841" className="black-sm">
              <span className="no-wrap bold-sm">1-844-647-2841</span>
            </a>
            .
          </strong>
        </p>
        <p className="footnotes">CRC=colorectal cancer. </p>
      </section>
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>
            KRAZATI may provide a response to{' '}
            <em className="not-bold">KRAS&nbsp;G12C</em>.
          </p>{' '}
          <Link to="/c/taking-krazati">
            <em>HOW TO TAKE KRAZATI&nbsp;&gt;</em>
          </Link>
        </div>
      </section>
    </>
  );
}
