// React Imports
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Error(props) {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("error");
  }, []);

  return (
    <>
      <Helmet>
        <title>KRAZATI&reg; (adagrasib) | 404</title>
        <meta name="description" content="" />
      </Helmet>
      <section className="hero blue-bg">
        <h2 className="rotate-left">
          <span>404</span>. Sorry, the page you're looking for cannot be found.
        </h2>
        <Link to="/" className="button green arrow">
          Return Home
        </Link>
      </section>
    </>
  );
}
