import React from "react";
import "./quote.scss";

function Quote({ text, author, image, alt = "An arm is holding up a green quote mark to indicate a patient testimonial about Mirati" }) {
  return (
    <div className="quote-container">
      <img
        src={image}
        alt={alt}
      />
      <div className="quote-text">
        {text}
        {"”"}
        <div className="author">{author}</div>
      </div>
    </div>
  );
}
export default Quote;
