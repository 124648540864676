// React Imports
import React, { useEffect } from 'react';
// import { useOutletContext } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Import Components
import Accordion from '../../components/Accordion';
import Hero from '../../components/Hero';
import PurpleBanner from '../../components/PurpleBanner';
import AnchorScroll from '../../components/AnchorScroll';

// Import Images
import krazCetuximab from '../../images/IMG_Krazati_cetuximab.jpg';
import recommenedDosageM from '../../images/IMG_2X_Dose_m.jpg';
import recommenedDosage from '../../images/IMG_2X_Dose.jpg';

export default function CTakingKrazati() {
  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('taking-krazati', 'crc');
  }, []);

  const accordionData = [
    {
      id: '1',
      title: 'Diarrhea',
      content: (
        <>
          <ul>
            <li>Drinking fluids may help you stay hydrated</li>
            <li>
              Eating smaller meals with foods that might be easy on your
              stomach. Foods that are high in potassium and sodium may help
              replace minerals that your body has lost
            </li>
            <li>Discuss other possible treatments with your doctor</li>
          </ul>
        </>
      ),
    },
    {
      id: '2',
      title: 'Nausea and Vomiting',
      content: (
        <>
          <ul>
            <li>Drink plenty of fluids—clear liquids and ice-cold beverages</li>
            <li>
              Eat smaller, more frequent meals. Nausea may be worse on an empty
              stomach
            </li>
            <li>
              Pay attention to which foods bother your stomach and try to avoid
              them (ie, greasy, fried, sweet, or spicy foods)
            </li>
            <li>
              Talk with your doctor about possibly taking antinausea medicine
            </li>
          </ul>
        </>
      ),
    },
    {
      id: '3',
      title: 'Tiredness/Fatigue',
      content: (
        <>
          <ul>
            <li>
              Track your energy level throughout the day, and have the
              information on hand to discuss with your doctor
            </li>
            <li>
              Balance rest and activity, prioritizing your most necessary
              activities for when you may have the most energy
            </li>
            <li>
              Staying hydrated and eating foods high in protein may increase
              your energy levels
            </li>
            <li>
              If feelings/emotions are disturbing your sleep, talk to your
              doctor about a referral to a specialist
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Managing Krazati&reg; (adagrasib) Treatment</title>
        <meta
          name="description"
          content="Learn about taking KRAZATI® (adagrasib), including dosages, administration, and side effects."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="Taking"
        subtitle={
          <>
            KRAZATI<sup>&reg;</sup>
            <span>(adagrasib)</span>
          </>
        }
      />
      <section>
        <h3>
          KRAZATI is a 600-mg, twice-daily, chemo-free, targeted oral medication
          that is taken with cetuximab, an infusion therapy.
        </h3>
      </section>
      <section className="align-center">
        <img
          src={krazCetuximab}
          alt="Image showing tablet icon plus infusion therapy icon"
          className="krazcetuximab"
        />
        <h3>KRAZATI Recommended Dosage: 600 MG TWICE DAILY</h3>
        <img
          src={recommenedDosageM}
          className="mobile-only"
          alt="Chart showing the recommended dosage "
        />
        <img
          src={recommenedDosage}
          className="desktop-only"
          alt="Chart showing the recommended dosage "
        />
      </section>
      <section>
        <h2 className="rotate-left">Taking krazati</h2>
        <h3>Take KRAZATI exactly as your doctor tells you to take it.</h3>
        <ul>
          <li>
            Take your prescribed dose of KRAZATI 2 times each day, at the same
            time each day, either with or without food
          </li>
          <li>
            Swallow KRAZATI tablets whole. Do not chew, crush, or split tablets
          </li>
          <li>
            Your doctor may change your dose, or temporarily or permanently stop
            treatment with KRAZATI if you develop certain side effects
          </li>
          <li>
            Do not change your dose or stop taking KRAZATI unless your doctor
            tells you to
          </li>
          <li>
            Your doctor will permanently or temporarily stop your treatment with
            cetuximab if your treatment with KRAZATI is permanently or
            temporarily stopped
          </li>
          <li>
            If you vomit after taking a dose of KRAZATI, do not take an extra
            dose. Take your next dose at your next scheduled time
          </li>
        </ul>
        <br />
        <p>
          <strong>If you miss a dose of KRAZATI:</strong>
        </p>
        <ul className="ntm nbm">
          <li>Take it as soon as you remember</li>
        </ul>
        <p>
          <strong>If it has been more than 4 hours:</strong>
        </p>
        <ul className="ntm">
          <li>
            <strong className="semi">
              Do not take the dose; take your next dose
            </strong>{' '}
            at your next scheduled time
          </li>
          <li>
            <strong className="semi">
              Do not take 2 doses at the same time
            </strong>{' '}
            to make up for a missed dose
          </li>
        </ul>
      </section>
      <section className="purple-banner thin">
        <div className="content-wrapper">
          <p>
            Your cetuximab infusion will be administered by your doctor. <br />
            Talk to your doctor about your cetuximab therapy.
          </p>
        </div>
      </section>
      <section id="what-to-expect" className="what-to-expect">
        <div className="container">
          <h2 className="rotate-left">WHAT TO EXPECT ON KRAZATI</h2>
          <h3 className="mbp">
            Therapy with KRAZATI can lead to side effects. It’s important to
            talk to your doctor right away if you experience any side effects.
          </h3>

          <h3>If you’re taking other medication</h3>
          <p>
            Tell your healthcare provider about all the medicines you take,
            including prescription and over-the-counter medicines, vitamins, and
            herbal supplements. KRAZATI can affect the way other medicines work,
            and other medicines can affect how KRAZATI works.
          </p>

          <h3>
            Side effects can vary from person to person. Concentrate on your own
            unique experience, and remember these ways to help manage them:
          </h3>
          <div className="accordion">
            {accordionData.map(({ id, title, content }) => (
              <Accordion key={id} title={title} content={content} />
            ))}
          </div>
          <p>
            <strong>
              The tips provided here are for informational purposes only and are
              not meant to replace a physician’s medical advice. Please contact
              your healthcare team if you are experiencing these side effects,
              and ask your doctor if these tips may be right for you.
            </strong>
          </p>
        </div>
      </section>
      <PurpleBanner
        text="Help is available for you."
        linkText="DISCOVER RESOURCES"
        link="/c/support"
      />
    </>
  );
}
