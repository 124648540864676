// React Imports
import React, { useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import Components
import Accordion from "../../components/Accordion";
import Hero from "../../components/Hero";
import PurpleBanner from "../../components/PurpleBanner";
import AnchorScroll from "../../components/AnchorScroll";

// Import Images
import directionsM from "../../images/dosage-m.svg";
import directions from "../../images/dosage-d.svg";

export default function NTakingKrazati() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("taking-krazati", "nsclc");
  }, []);

  // const openModal = useOutletContext();

  const accordionData = [
    {
      id: "1",
      title: "Diarrhea",
      content: (
        <>
          <ul>
            <li>Drinking fluids may help you stay hydrated</li>
            <li>
              Eating smaller meals with foods that might be easy on your
              stomach. Foods that are high in potassium and sodium may help
              replace minerals that your body has lost
            </li>
            <li>Discuss other possible treatments with your doctor</li>
          </ul>
        </>
      ),
    },
    {
      id: "2",
      title: "Nausea and Vomiting",
      content: (
        <>
          <ul>
            <li>Drink plenty of fluids—clear liquids and ice-cold beverages</li>
            <li>
              Eat smaller, more frequent meals. Nausea may be worse on an empty
              stomach
            </li>
            <li>
              Pay attention to which foods bother your stomach and try to avoid
              them (ie, greasy, fried, sweet, or spicy foods)
            </li>
            <li>
              Talk with your doctor about possibly taking antinausea medicine
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "3",
      title: "Tiredness/Fatigue",
      content: (
        <>
          <ul>
            <li>
              Track your energy level throughout the day, and have the
              information on hand to discuss with your doctor
            </li>
            <li>
              Balance rest and activity, prioritizing your most necessary
              activities for when you may have the most energy
            </li>
            <li>
              Staying hydrated and eating foods high in protein may increase
              your energy levels
            </li>
            <li>
              If feelings/emotions are disturbing your sleep, talk to your
              doctor about a referral to a specialist
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>All About Taking KRAZATI&reg; (adagrasib)</title>
        <meta
          name="description"
          content="Learn about taking KRAZATI® (adagrasib). Follow recommended dosages and manage side effects."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="Taking"
        subtitle={
          <>
            KRAZATI<sup>&reg;</sup>
            <span>(adagrasib)</span>
          </>
        }
      />
      <section>
        <h2 className="rotate-left">TAKING KRAZATI</h2>
        <h3>Take KRAZATI exactly as your doctor tells you to take it.</h3>
      </section>
      <section className="no-max-width">
        <div className="img-container">
          <div
            style={{ color: "#3246FF", textAlign: "center", marginBottom: 15 }}
          >
            KRAZATI Recommended Dosage
          </div>
          <img
            className="mobile-only"
            src={directionsM}
            alt="Chart showing the KRAZATI - adagrasib recommended dosage and tablet sizes"
          />
          <img
            className="desktop-only"
            src={directions}
            alt="Chart showing the KRAZATI - adagrasib recommended dosage and tablet sizes"
          />
        </div>
      </section>
      <section>
        <ul>
          <li>Taken orally (not an injection)</li>
          <li>
            Take your prescribed dose of KRAZATI 2 times a day, at about the
            same time each day, either with or without food
          </li>
          <li>
            Swallow KRAZATI tablets whole. Do not chew, crush, or split tablets
          </li>
          <li>
            Your doctor may change your dose, or temporarily or permanently stop
            treatment with KRAZATI if you develop certain side effects
          </li>
        </ul>
        <br />
        {/* <p className="mb">
          Take your prescribed dose of KRAZATI 2 times a day, at about the same
          time each day, either with or without food
        </p> */}
        <p>
          <strong>
            Do not change your dose or stop taking KRAZATI unless your doctor
            tells you to.
          </strong>
        </p>
        <ul className="ntm">
          <li>
            If you vomit after taking a dose of KRAZATI, do not take an extra
            dose. Take your next dose at your next scheduled time
          </li>
        </ul>
        <p>
          <strong>If you miss a dose of KRAZATI:</strong>
        </p>
        <ul className="ntm">
          <li>Take it as soon as you remember</li>
          <li>
            <strong>By more than 4 hours?</strong> Do not take the dose; take
            your next dose at your next scheduled time
          </li>
          <li>
            Do not take 2 doses at the same time to make up for a missed dose
          </li>
        </ul>
      </section>
      <PurpleBanner
        text="The recommended dosage for KRAZATI is 600 mg orally, twice daily."
        linkText=""
        link=""
      />
      <section id="what-to-expect" className="what-to-expect">
        <div className="container">
          <h2 className="rotate-right">WHAT TO EXPECT ON KRAZATI</h2>
          <h3 className="mbp">
            Therapy with KRAZATI can lead to side effects. It’s important to
            talk to your doctor right away if you experience any side effects.
          </h3>

          <h3>If you’re taking other medication</h3>
          <p>
            Tell your healthcare provider about all the medicines you take,
            including prescription and over-the-counter medicines, vitamins, and
            herbal supplements. KRAZATI can affect the way other medicines work,
            and other medicines can affect how KRAZATI works.
          </p>

          <h3>
            Side effects can vary from person to person. Concentrate on your own
            unique experience, and remember these ways to help manage them:
          </h3>
          <div className="accordion">
            {accordionData.map(({ id, title, content }) => (
              <Accordion key={id} title={title} content={content} />
            ))}
          </div>
          <p className="mb">
            <strong>
              The tips provided here are for informational purposes only and are
              not meant to replace a physician’s medical advice. Please contact
              your healthcare team if you are experiencing these side effects,
              and ask your doctor if these tips may be right for you.
            </strong>
          </p>
        </div>
      </section>
      <PurpleBanner
        text="Help is available for you."
        linkText="DISCOVER SUPPORT & RESOURCES"
        link="/n/support"
      />
    </>
  );
}
