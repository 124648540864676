import React from "react";
import { Link } from "react-router-dom";

export default function purpleBanner(props) {
  const hasLink = props.linkText;

  if (!hasLink) {
    return (
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>{props.text}</p>
        </div>
      </section>
    );
  } else {
    return (
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>{props.text}</p>{" "}
          <Link to={props.link}>
            <em>{props.linkText}&nbsp;&gt;</em>
          </Link>
        </div>
      </section>
    );
  }
}
