import React, { useState } from "react";
import caret from "../images/icon_nav_caret.svg";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <p className="accordion-title-text">{title}</p>
        <img
          className={isActive ? "caret expanded" : "caret collapsed"}
          src={caret}
          alt="^"
        />
      </div>
      <div
        className={
          isActive
            ? "accordion-content expanded"
            : "accordion-content collapsed"
        }
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
