// React Imports
import React, { useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import Components
import Hero from "../../components/Hero";
import PurpleBanner from "../../components/PurpleBanner";
import AnchorScroll from "../../components/AnchorScroll";

// Import Images
import type1 from "../../images/KRAZATI_PAT-WEB_ILLIMOA-01.jpg";
import type2 from "../../images/KRAZATI_PAT-WEB_ILLIMOA-02.jpg";

export default function NAboutKrazati() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("about-krazati", "nsclc");
  }, []);

  // const openModal = useOutletContext();

  return (
    <>
      <Helmet>
        <title>About KRAZATI&reg; (adagrasib) | Overview and Treatment</title>
        <meta
          name="description"
          content="Find out how KRAZATI® (adagrasib), an oral, FDA approved treatment, works for adult patients."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="About"
        subtitle={
          <>
            KRAZATI<sup>&reg;</sup>
            <span>(adagrasib)</span>
          </>
        }
      />
      <section>
        <h3>KRAZATI is an oral, targeted therapy you can take at home.</h3>
        <p>
          <strong>KRAZATI is not chemotherapy.</strong>
          <br />
          It is an oral prescription medicine that can be taken at home and is
          used to treat adults with NSCLC that has spread to other parts of the
          body or cannot be removed by surgery, and whose tumor has an abnormal{" "}
          <em>KRAS G12C</em> gene, and who have received at least one prior
          treatment for their cancer.
          <br />
          <br />
          Your healthcare provider will perform a test to make sure KRAZATI is
          right for you. It is not known if KRAZATI is safe and effective in
          children.
        </p>
        <ul>
          <li>
            KRAZATI is a targeted therapy designed to attack the mutated{" "}
            <em>KRAS</em> protein
          </li>
          <li>
            KRAZATI is a treatment made to target your disease so that you can
            focus on what’s most important to you
          </li>
        </ul>
      </section>
      <section>
        <h3>KRAZATI is designed with your specific type of cancer in mind.</h3>
        <div className="types-container">
          <div className="types">
            <img src={type1} alt="Illustration describing mutation" />
            <p>
              <em>KRAS</em> makes a protein that, when mutated, like{" "}
              <em>KRAS&nbsp;G12C</em>, can set off a domino effect that can lead
              to cancer.
            </p>
          </div>
          <div className="types">
            <img
              src={type2}
              alt="Illustration representing how KRAZATI - adagrasib works"
            />
            <p>
              KRAZATI is designed to target KRAS&nbsp;G12C in order to suppress
              tumor growth.
            </p>
          </div>
        </div>
        <p className="footnotes">NSCLC=non-small cell lung cancer.</p>
      </section>
      <PurpleBanner
        text="KRAZATI is designed to suppress KRAS&nbsp;G12C tumor growth."
        linkText="SEE STUDY RESULTS"
        link="/n/study-results"
      />
    </>
  );
}
