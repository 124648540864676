/* eslint-disable jsx-a11y/img-redundant-alt */
// React Imports
import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Import components
import Hero from '../../components/Hero';
import PurpleBanner from '../../components/PurpleBanner';
import AnchorScroll from '../../components/AnchorScroll';
import Quote from '../../components/Quote/Quote';

// Library Import
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import Images
import ill1 from '../../images/ill_1.jpg';
import ill2 from '../../images/ill_2.jpg';
import ill3 from '../../images/ill_3.jpg';
import ill_carousel_1 from '../../images/ill_carousel_1.png';
import ill_carousel_2 from '../../images/ill_carousel_2.png';
import ill_carousel_3 from '../../images/ill_carousel_3.png';
import ill_carousel_4 from '../../images/ill_carousel_4.png';
import handQuotation from '../../images/hand_quotation_marks.png';
// import quotes from "../images/quotes.png";

export default function NAboutKRASG12C() {
  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('aboutKRASG12C', 'nsclc');

    addAnimation();
    setTimeout(() => {
      stopPulse();
    }, 100);
  }, []);

  function addAnimation() {
    const slide = document.querySelector('.slick-track');
    slide.setAttribute('data-aos', 'slide-animation');
  }

  // Custom Slick Arrows
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  }

  // Slick Slider Settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function stopPulse() {
    const prevArrow = document.querySelector('.slick-arrow.slick-prev');
    const nextArrow = document.querySelector('.slick-arrow.slick-next');

    nextArrow.addEventListener('click', () => {
      setTimeout(() => {
        nextArrow.classList.add('no-pulse');
      }, 100);
    });
    nextArrow.addEventListener('touchstart', () => {
      setTimeout(() => {
        nextArrow.classList.add('no-pulse');
      }, 100);
    });
    prevArrow.addEventListener('click', () => {
      setTimeout(() => {
        nextArrow.classList.add('no-pulse');
      }, 100);
    });
    prevArrow.addEventListener('touchstart', () => {
      setTimeout(() => {
        nextArrow.classList.add('no-pulse');
      }, 100);
    });
  }

  const [handleOpenModal] = useOutletContext();

  return (
    <>
      <Helmet>
        <title>Common Driver Mutation | Overview and Biomarker Testing</title>
        <meta
          name="description"
          content="Learn about the common driver mutation, significance of biomarker testing, and treatment options."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="About"
        subtitle={
          <>
            <em>KRAS&nbsp;G12C</em>
          </>
        }
      />
      <section className="pr">
        <h2 className="rotate-left">
          ABOUT <em>KRAS&nbsp;G12C</em> IN NSCLC
        </h2>
        <h3>Cell mutations can lead to the development of NSCLC.</h3>
        <p>
          Lung cancer is a cancer that starts in the lungs. The type of lung
          cancer you have—NSCLC—develops from mutations in lung cells.
          <br />
          <br />
          People with cancer can have changes to one or more genes in a cell
          that cause it to not work properly. These mutations lead to abnormal
          growth of cells. The <em>KRAS&nbsp;G12C</em> mutation is one of many
          mutations that may be found in NSCLC.
        </p>
      </section>
      <section>
        <h3>
          <em>KRAS&nbsp;G12C</em> is a common mutation.
        </h3>
        <div className="mutation-container">
          <div className="mutations">
            <img src={ill1} alt="Illustration explaining common mutations" />
            <p className="medium">
              <em>KRAS</em> mutations are among the most common mutations that
              may lead to NSCLC.
            </p>
          </div>
          <div className="mutations">
            <img src={ill2} alt="Illustration describing common mutations" />
            <p className="medium">
              About 1 in 8 people with NSCLC have a <em>KRAS&nbsp;G12C</em>{' '}
              mutation.
            </p>
          </div>
          <div className="mutations">
            <img
              src={ill3}
              alt="An image representing how the growth and development of cells can be controlled"
            />
            <p className="medium">
              The growth and development of cells can be controlled by{' '}
              <em>KRAS</em>. When it is mutated, this can set off a domino
              effect that can lead to cancer.
            </p>
          </div>
        </div>
      </section>
      <section className="slick-container no-max-width">
        <h3 className="slider-header">KRAS works like an on/off switch.</h3>
        <p className="click-to-learn-more">
          Click the purple arrows to learn more.
        </p>
        <Slider {...settings}>
          <div>
            <img
              src={ill_carousel_1}
              alt="A carousel of images describing how the growth and development of cells works like an on/off switch"
            />
            <p>
              <em>KRAS</em> is a gene that makes a&nbsp;protein
            </p>
          </div>
          <div>
            <img src={ill_carousel_2} alt="ill_carousel_2" />
            <p>This protein works like an on/off&nbsp;switch</p>
          </div>
          <div>
            <img src={ill_carousel_3} alt="ill_carousel_3" />
            <p>
              <em>KRAS&nbsp;G12C</em> can make the switch stay&nbsp;“on”
            </p>
          </div>
          <div>
            <img src={ill_carousel_4} alt="ill_carousel_4" />
            <p>
              This can cause cancer cells to grow and spread (metastasize)
              through the&nbsp;body
            </p>
          </div>
        </Slider>
      </section>
      <section id="biomarker-testing" className="testing pr">
        <h2 className="rotate-right">About Biomarker testing</h2>
        <h3>
          Your cancer may have biomarkers that can be identified
          through&nbsp;testing.
        </h3>
        <p>
          Biomarker testing is a way to look for genes and proteins that can
          provide information about cancer. Each person’s cancer has a unique
          pattern of biomarkers.
          <br />
          <br />
          Your doctor can test you by using a biopsy, either tissue from your
          tumor (a tissue-based test) or blood (a liquid biopsy test).
        </p>
        <a
          href="https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment"
          target="_blank"
          rel="noreferrer"
          className="button purple"
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal(
              'https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment'
            );
          }}
        >
          Learn more about biomarker testing
        </a>
      </section>
      <section className="testing pr ntp">
        <h3>
          Knowing your mutation status can help your doctor find the best
          treatment approach for&nbsp;you.
        </h3>
        <p>
          Don’t wait any longer to get the knowledge you deserve. Ask your
          doctor to test for <em>KRAS&nbsp;G12C</em> to determine your mutation
          status. Your doctor may have already tested you when you were first
          diagnosed and can look back to check for <em>KRAS&nbsp;G12C</em>.
          <br />
          <br />
          If your advanced lung cancer is <em>KRAS&nbsp;G12C</em>-positive, your
          next move is simple. Ask your doctor if KRAZATI may be right for you.
        </p>
        <Link to="/n/about-krazati" className="button purple more-margin">
          Learn more about KRAZATI
        </Link>
        <p className="footnotes">NSCLC=non-small cell lung cancer.</p>
      </section>
      {/* Old Quote section markup. Commenting out and leaving it here for now */}
      {/* <section className="quote blue">
        <div className="content-wrapper">
          <img
            className="quotes hands"
            src={handQuotation}
            alt="Hand Quotation Marks"
          />
          <img className="quotes no-hands" src={quotes} alt="Quotation Marks" />
          <p>
            Get an understanding of your biomarker mutations and use that to
            make sure you find the best possible treatment&nbsp;approach.”
            <span className="name">KRAZATI TRIAL PARTICIPANT</span>
          </p>
        </div>
      </section> */}
      <Quote
        text="Get an understanding of your biomarker mutations and use that to make sure you find the best possible treatment approach."
        author="KRAZATI TRIAL PARTICIPANT"
        image={handQuotation}
      />
      <PurpleBanner
        text="The right treatment option for you may be just a question away."
        linkText="LEARN MORE ABOUT KRAZATI"
        link="/n/about-krazati"
      />
    </>
  );
}
