// React Imports
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorScroll from "../../components/AnchorScroll";

// Img Imports
import down from "../../images/down_arrow.svg";
import handQuotation from "../../images/hand_quotation_marks.png";
import quotes from "../../images/quotes.png";
import graphicM from "../../images/homepage-graphic-yellow-m.png";
import graphicD from "../../images/homepage-graphic-yellow-d.png";

export default function CHome() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("home", "crc");
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [handleOpenModal, handleOpenFormModal] = useOutletContext();

  function scrollDown() {
    const targetSection = document.querySelector(".hero + section");
    let topPos;

    if (window.innerWidth < 1024) {
      topPos = targetSection.offsetTop + 150;
    } else {
      topPos = targetSection.offsetTop + 350;
    }

    window.scroll({
      top: topPos,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <Helmet>
        <title>Approved Treatment Options | Information for Patients</title>
        <meta
          name="description"
          content="Welcome to our patient information center. Gain valuable knowledge regarding advanced disease, treatment, and support."
        />
      </Helmet>
      <AnchorScroll />
      <section id="phero" className="hero">
        <div id="floater" className="hero-wrapper">
          <h1>
            <span className="small">
              In <em>KRAS&nbsp;G12C</em>-positive advanced CRC
            </span>
            This is my
            <span className="large yellow">
              time to target<br></br> what matters
            </span>
          </h1>
          <p>
            You have had prior therapy for advanced CRC. KRAZATI + cetuximab
            combination therapy is a chemo-free, targeted treatment option for{" "}
            <em>KRAS G12C</em>-positive advanced CRC.
          </p>
          <div id="signup-cta" className="ctas">
            <Link to="/c/about-biomarkers" className="cta">
              <p className="cta-header">Designed for your specific cancer</p>
              <p>
                Learn how KRAZATI <span className="last-word">works</span>
              </p>
            </Link>
          </div>
        </div>
        <p className="footnote">Not an actual patient.</p>
      </section>
      <section className="blue">
        <div className="content-wrapper">
          <div className="scroll-down dt" onClick={scrollDown}>
            <img src={down} alt="down" />
          </div>
          <div className="color-ctas">
            <Link
              to="/c/about-biomarkers#biomarker-testing"
              className="cta purple"
            >
              <p className="cta-header">HAVE YOU HAD A BIOMARKER TEST?</p>
              <p className="footer">
                Learn why it&rsquo;s{" "}
                <span className="last-word">essential</span>
              </p>
            </Link>
            <Link to="/c/support" className="cta green">
              <p className="cta-header">
                RESOURCES
                <br />
                DESIGNED FOR YOU
              </p>
              <p className="footer">
                Get personalized <span className="last-word">help</span>
              </p>
            </Link>
            <Link to="/c/study-results" className="cta pink">
              <p className="cta-header">THE KRAZATI EXPERIENCE</p>
              <p className="footer">
                See clinical trial <span className="last-word">results</span>
              </p>
            </Link>
          </div>
          <div className="quote">
            <img
              className="graphic mobile-only"
              src={graphicM}
              alt="KRAZATI - adagrasib 200 mg tablets"
            />
            <img
              className="graphic desktop-only"
              src={graphicD}
              alt="KRAZATI - adagrasib 200 mg tablets"
            />
          </div>
          <p className="footnotes white">CRC=colorectal cancer.</p>
        </div>
      </section>
    </>
  );
}
