// React Imports
import React from "react";

// Library Imports
import ReactModal from "react-modal";

// Image Imports
import x from "../images/icon_close.svg";

export default function InterstitialModal(props) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.modal === 1}
      contentLabel="Interstitial Modal"
    >
      <img
        className="x show"
        src={x}
        alt="x"
        onClick={() => {
          props.closeModal();
        }}
      />
      <p className="subtitle">before you go</p>
      <p>Are you ready to Leave the krazati website?</p>
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        className="button purple"
        onClick={() => {
          props.closeModal();
        }}
      >
        Continue
      </a>
    </ReactModal>
  );
}
