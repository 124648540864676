// React Imports
import React from "react";

// Library Imports
import ReactModal from "react-modal";

// Image Imports
import x from "../images/icon_close.svg";

export default function BMSModal(props) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.modal === 1}
      contentLabel="BMS Modal"
      className="bms-modal"
    >
      <img
        className="x show"
        src={x}
        alt="x"
        onClick={(e) => {
          e.preventDefault();
          props.closeModal();
        }}
      />
      <h2>Mirati is now a<br/> Bristol Myers Squibb company</h2>
      <p>
        Select <a className="close-modal" href="" onClick={(e) => {e.preventDefault(); props.closeModal();}}>Accept</a> for Information about Mirati.<br/>
        To learn more about Bristol Myers Squibb <a href="//www.bms.com/" target="_blank" rel="noopener" data-cta-type="cta" onClick={(e) => {
          props.closeModal();
        }}>Click Here</a>.
      </p>
    </ReactModal>
  );
}

