// React Imports
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorScroll from "../components/AnchorScroll";

export default function Home() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("home", "landing");
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [handleOpenModal, handleOpenFormModal] = useOutletContext();

  return (
    <>
      <Helmet>
        <title>KRAZATI&reg; (adagrasib) | Information for Patients</title>
        <meta
          name="description"
          content="Discover KRAZATI® (adagrasib), an oral treatment that can
          be taken at home. Learn about safety, testing, and support options."
        />
      </Helmet>
      <AnchorScroll />

      <section id="phero" className="hero">
        <div id="floater" className="hero-wrapper">
          <h1>
            <strong>KRAZATI</strong> is targeted therapy
          </h1>
          <h2>
            for adults with previously treated
            <br /> <em>KRAS G12C</em>-positive advanced NSCLC or CRC
          </h2>
        </div>
      </section>
      <section className="blue">
        <div className="content-wrapper">
          <p className="heading">Choose Condition:</p>
          <div className="color-ctas">
            <Link to="/n" className="cta nsclc">
              <p className="cta-header">
                NON-SMALL CELL <br />
                LUNG CANCER
              </p>
              <p className="footer">Visit site</p>
            </Link>
            <Link to="/c" className="cta crc">
              <p className="cta-header">
                COLORECTAL <br />
                CANCER
              </p>
              <p className="footer">Visit site</p>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
