// React Imports
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, redir } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import ScrollToTop from "./components/ScrollToTop";

// Styles
import "./scss/main.scss";

// Components
import App from "./routes/App";
import Home from "./routes/Home";
import CHome from "./routes/c/CHome";
import CAboutKRASG12C from "./routes/c/CAboutKRASG12C";
import CAboutKrazati from "./routes/c/CAboutKrazati";
import CClinicalTrialResults from "./routes/c/CClinicalTrialResults";
import CTakingKrazati from "./routes/c/CTakingKrazati";
import CSupport from "./routes/c/CSupport";
import CReferences from "./routes/c/CReferences";
import NHome from "./routes/n/NHome";
import NAboutKRASG12C from "./routes/n/NAboutKRASG12C";
import NAboutKrazati from "./routes/n/NAboutKrazati";
import NClinicalTrialResults from "./routes/n/NClinicalTrialResults";
import NTakingKrazati from "./routes/n/NTakingKrazati";
import NSupport from "./routes/n/NSupport";
import NReferences from "./routes/n/NReferences";
import Error from "./routes/Error";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Error />} />
          <Route path="/c" element={<CHome />} />
          <Route path="/c/about-biomarkers" element={<CAboutKRASG12C />} />
          <Route path="/c/about-krazati" element={<CAboutKrazati />} />
          <Route path="/c/study-results" element={<CClinicalTrialResults />} />
          <Route path="/c/taking-krazati" element={<CTakingKrazati />} />
          <Route path="/c/support" element={<CSupport />} />
          <Route path="/c/references" element={<CReferences />} />
          <Route path="/n" element={<NHome />} />
          <Route path="/n/about-biomarkers" element={<NAboutKRASG12C />} />
          <Route path="/n/about-krazati" element={<NAboutKrazati />} />
          <Route path="/n/study-results" element={<NClinicalTrialResults />} />
          <Route path="/n/taking-krazati" element={<NTakingKrazati />} />
          <Route path="/n/support" element={<NSupport />} />
          <Route path="/n/references" element={<NReferences />} />

          {/* Redirects */}
          {/*
          Added redirects to index.php

          Leaving here to catch any urls not updated in the js app.
          */}
          <Route path="/about-KRAS-G12C" element={<Navigate replace to="/n/about-biomarkers" />} />
          <Route path="/about-krazati" element={<Navigate replace to="/n/about-krazati" />} />
          <Route path="/study-results" element={<Navigate replace to="/n/study-results" />} />
          <Route path="/taking-krazati" element={<Navigate replace to="/n/taking-krazati" />} />
          <Route path="/support" element={<Navigate replace to="/n/support" />} />
          <Route path="/references" element={<Navigate replace to="/n/references" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
