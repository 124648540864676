// React Imports
import React, { useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import Components
import Hero from "../../components/Hero";
import PurpleBanner from "../../components/PurpleBanner";
import AnchorScroll from "../../components/AnchorScroll";

// Import Images
import type1 from "../../images/img-cancer-1.jpg";
import type2 from "../../images/img-cancer-2.jpg";

export default function CAboutKrazati() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("about-krazati", "crc");
  }, []);

  // const openModal = useOutletContext();

  return (
    <>
      <Helmet>
        <title>Understanding Krazati&reg; (adagrasib) Treatment</title>
        <meta
          name="description"
          content="Explore Krazati&reg; (adagrasib) + cetuximab, an FDA-approved treatment. Learn about its mechanism and patient eligibility."
        />
      </Helmet>
      <AnchorScroll />
      <Hero
        title="About"
        subtitle={
          <>
            KRAZATI<sup>&reg;</sup>
            <span>(adagrasib)</span>
          </>
        }
      />
      <section>
        <h2 className="rotate-left">About KRAZATI </h2>

        <p>
          <strong>
            KRAZATI + cetuximab combination therapy is a chemo-free, targeted
            treatment option for adults with CRC{" "}
          </strong>
          that has spread to other parts of the body or cannot be removed by
          surgery, and whose tumor has an abnormal <em>KRAS G12C</em> gene, and
          who have previously received certain chemotherapy medicines.
          <br />
          <br />
          Your healthcare provider will perform a test to make sure that KRAZATI
          is right for you. It is not known if KRAZATI is safe and effective in
          children.
        </p>
        <ul>
          <li>
            KRAZATI is a targeted therapy designed to attack the mutated KRAS
            protein
          </li>
          <li>KRAZATI is a treatment made to target your disease</li>
        </ul>
      </section>
      <section>
        <h3>KRAZATI is designed with your specific type of cancer in mind.</h3>
        <div className="types-container">
          <div className="types">
            <img src={type1} alt="Illustration describing mutation" />
            <p>
              <em>KRAS</em> makes a protein that, when mutated, like{" "}
              <em>KRAS&nbsp;G12C</em>, can set off a domino effect that can lead
              to cancer.
            </p>
          </div>
          <div className="types">
            <img
              src={type2}
              alt="Illustration representing how the medication works"
            />
            <p>
              KRAZATI is designed to target KRAS G12C in order to help suppress
              tumor growth.
            </p>
          </div>
        </div>
        <p className="footnotes">CRC=colorectal cancer. </p>
      </section>
      <PurpleBanner
        text="See how people responded to KRAZATI combination therapy."
        linkText="GO TO STUDY RESULTS"
        link="/c/study-results"
      />
    </>
  );
}
