// React Imports
import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Import Images
import logo from "../images/logo_krazati-head-m.svg";
import IndicationMenu from "./IndicationMenu";

export default function Header(props) {
  const location = useLocation();
  useEffect(() => {
    fixFlow();
    checkForPopupForm();

    stickyNavSetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle nav menu
  function toggleMenu() {
    const nav = document.querySelector(".nav");
    const isi = document.getElementById("fixed-isi");
    const menuToggle = document.querySelector(".menu-toggle");
    const html = document.querySelector("html");
    const header = document.querySelector("header");
    const scrollable = document.querySelector(".scroll-container");

    fixFlow();

    if (!isi.classList.contains("expanded")) {
      const collapsedIsiHeight = isi.offsetHeight + 55;
      const violator = document.getElementById("violator");

      if (violator && !header.classList.contains("sticky")) {
        nav.style.height = `calc(100% - ${
          collapsedIsiHeight + violator.offsetHeight
        }px)`;
      } else {
        nav.style.height = `calc(100% - ${collapsedIsiHeight}px)`;
      }
    }

    html.classList.toggle("no-scroll");
    nav.classList.toggle("mobile-nav");
    !menuToggle.classList.contains("is-active") &&
      scrollable.scroll({ top: 0 });
    menuToggle.classList.toggle("is-active");

    if (isi.classList.contains("hidden")) {
      // nav.style.height = "100%";
      isi.classList.remove("hidden");
      isi.classList.add("was-hidden");
    } else if (isi.classList.contains("was-hidden")) {
      isi.classList.add("hidden");
      isi.classList.remove("was-hidden");
    }
  }

  function closeMenu() {
    // variables
    const nav = document.querySelector(".nav");
    const menuToggle = document.querySelector(".menu-toggle");
    const html = document.querySelector("html");
    const isi = document.getElementById("fixed-isi");

    // remove classes
    nav.classList.remove("mobile-nav");
    menuToggle.classList.remove("is-active");
    html.classList.remove("no-scroll");
  }

  function fixFlow() {
    setTimeout(() => {
      const violator = document.getElementById("violator");
      const x = document.querySelector("#violator .x");
      const additionalInfo = document.querySelector(
        ".additional-info.mobile-only"
      );
      const scrollContainer = document.querySelector(
        ".nav > .scroll-container"
      );

      if (violator) {
        scrollContainer.classList.add("violator-open");
        additionalInfo.classList.add("violator-open");
        x.addEventListener("click", () => {
          fixFlow();
        });
      } else {
        // scrollContainer.classList.remove("violator-open");
        // additionalInfo.classList.remove("violator-open");
      }
    }, 100);
  }

  function stickyNavSetup() {
    window.onscroll = function () {
      if (window.innerWidth < 1024) {
        stickyNav();
      }
    };
  }

  function stickyNav() {
    const header = document.querySelector("header");
    let sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  function checkForPopupForm() {
    const formTrigger = getQuerystring("stay-connected");
    if (formTrigger) {
      props.openFormModal();
      document.addEventListener("visibilitychange", () => {
        props.openFormModal();
      });
    }
  }

  /**
   * Gets a querystring from the url
   * @param  {string} key      The querystring key
   * @param  {string} default_ The default if there is none setCookie
   * @return {string}          The value of the querystring referenced by the key
   */
  function getQuerystring(key, default_) {
    if (default_ === null) default_ = "";

    key = key.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");

    const regex = new RegExp("[\\?&]" + key + "=([^&#]*)");
    const qs = regex.exec(window.location.href);

    if (qs === null) return default_;

    return qs[1];
  }

  const siteSection = window.location.pathname.split("/")[1];

  return (
    <>
      <header>
        <nav className="secondary-nav">
          <a href="#important-safety-information">
            IMPORTANT SAFETY INFORMATION
          </a>
          <a
            href="https://www.bms.com/assets/packageinserts/ppi/ppi_krazati.pdf"
            target="_blank"
            rel="noreferrer"
          >
            PATIENT INFORMATION
          </a>
          <a
            href="https://packageinserts.bms.com/pi/pi_krazati.pdf"
            target="_blank"
            rel="noreferrer"
          >
            PRESCRIBING INFORMATION
          </a>
          <a
            href="https://www.krazatihcp.com"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              props.openModal("https://www.krazatihcp.com");
            }}
          >
            Visit Healthcare Professionals Site
          </a>
        </nav>
        <nav className="navbar">
          <div className="container">
            <Link to={`/${siteSection}`} onClick={closeMenu} className="logo">
              <img
                src={logo}
                alt="KRAZATI - adagrasib 200 mg tablets logo"
                title="Krazati"
              />
            </Link>
            <IndicationMenu></IndicationMenu>

            <div className="menu-toggle" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            <ul className="nav">
              <div className="scroll-container">
                <li className="nav-item mobile-only">
                  <NavLink to={`/${siteSection}/`} onClick={closeMenu}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/${siteSection}/about-biomarkers`}
                    onClick={closeMenu}
                  >
                    About <em>KRAS&nbsp;G12C</em>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/${siteSection}/about-krazati`}
                    onClick={closeMenu}
                  >
                    About Krazati
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/${siteSection}/study-results`}
                    onClick={closeMenu}
                  >
                    Clinical Trial Results
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/${siteSection}/taking-krazati`}
                    onClick={closeMenu}
                  >
                    Taking Krazati
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={`/${siteSection}/support`} onClick={closeMenu}>
                    {siteSection === "c" ? "Resources" : "Support & Resources"}
                  </NavLink>
                </li>
                <li className="nav-item crc-only">
                  <a
                    href="https://www.bmsaccesssupport.com/patient"
                    target="_blank"
                    rel="noreferrer"
                    onClick={closeMenu}
                  >
                    BMS ACCESS SUPPORT
                  </a>
                </li>
              </div>
              <div className="additional-info mobile-only">
                <ul>
                  <li>
                    <a href="#important-safety-information" onClick={closeMenu}>
                      IMPORTANT SAFETY INFORMATION
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bms.com/assets/packageinserts/ppi/ppi_krazati.pdf"
                      target="_blank"
                      rel="noreferrer"
                      onClick={closeMenu}
                    >
                      PATIENT INFORMATION
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://packageinserts.bms.com/pi/pi_krazati.pdf"
                      target="_blank"
                      rel="noreferrer"
                      onClick={closeMenu}
                    >
                      PRESCRIBING INFORMATION
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.krazatihcp.com"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        props.openModal("https://www.krazatihcp.com");
                      }}
                    >
                      Visit Healthcare Professionals Site
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}
