// React Imports
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';

// Content Imports
import IsiContent from './IsiContent';

// Image Imports
import caret from '../images/blue_arrow.svg';

export default function Isi() {
  const fixedIsiRef = useRef(null);
  const [windowWidth] = useWindowSize();
  const [isiCollapsed, setIsiCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', detectIsi);
    window.addEventListener('resize', fixedIsiPosition);

    // Check if Fixed Isi should be visible on load
    detectIsi();
    // Move Fixed Isi scroll position on load
    fixedIsiPosition();
    // force fixed isi on smaller windows
    if (location.pathname == '/') {
      if (window.innerHeight < 960) {
        document.getElementById('fixed-isi').classList.remove('hidden');
      }
    }
  }, [location, window.innerHeight]);

  useEffect(() => {
    fixedIsiRef.current.removeAttribute('style');
    fixedIsiRef.current.style.height = `${fixedIsiRef.current.offsetHeight}px`;
  }, [windowWidth]);

  // Checks if ISI is in view
  function detectIsi() {
    // Variables
    const element = document.getElementById('important-safety-information');
    const position = element.getBoundingClientRect();
    const stickyIsi = document.getElementById('fixed-isi');

    // check static ISI is in view
    if (position.top < window.innerHeight - 170) {
      // hide fixed ISI if static ISI is in view
      stickyIsi.classList.add('hidden');
    } else {
      // show fixed ISI if static ISI is not view
      stickyIsi.classList.remove('hidden');
    }
  }

  // Expands and Collapses Fixed Isi on click
  function toggleIsi(e) {
    const target = e.target;
    const isi = document.getElementById('fixed-isi');
    const html = document.querySelector('html');
    html.classList.toggle('no-scroll');
    isi.classList.toggle('expanded');
    setIsiCollapsed(!isiCollapsed);
    if (!target.classList.contains('desktop-only')) {
      mobileISI(target);
    }
  }

  function mobileISI(target) {
    const isi = document.getElementById('fixed-isi');
    const indication = document.querySelectorAll('#fixed-isi .indication');
    const isiContent = document.querySelector('#fixed-isi .isi');

    if (target.classList.contains('indication-toggle')) {
      isiContent.classList.remove('expand');
      indication.forEach((item) => {
        item.classList.add('expand');
      });
    } else if (target.classList.contains('isi-toggle')) {
      isiContent.classList.add('expand');
      indication.forEach((item) => {
        item.classList.remove('expand');
      });
    }
  }

  // Checks where Important Safety... section is and scrolls to it
  function fixedIsiPosition() {
    // Variables
    // const isi = document.getElementById('fixed-isi');
    // const isiHeader = document.querySelector('#fixed-isi .isi-header');
    // const topPos = isiHeader.offsetTop - 7;
    // Scroll to Important Safety... section
    // isi.scrollTo(0, topPos);
  }

  // Scrolls to top of Isi
  function topOfIsi() {
    const isi = document.getElementById('fixed-isi');
    isi.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <section
        id="important-safety-information"
        className="isi-container collapsed"
      >
        <IsiContent />
      </section>

      <section id="fixed-isi" className="isi-container fixed" ref={fixedIsiRef}>
        <a
          className="toggle desktop-only"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            toggleIsi(e);
          }}
        >
          <img src={caret} alt="^" />
        </a>
        <a
          className="toggle isi-toggle hide-desktop"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            toggleIsi(e);
          }}
        >
          <img src={caret} alt="^" />
        </a>
        <a
          className="toggle indication-toggle hide-desktop"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            toggleIsi(e);
          }}
        >
          <img src={caret} alt="^" />
        </a>
        <IsiContent />
      </section>
    </>
  );
}
