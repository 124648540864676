// React Imports
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function IndicationMenu(props) {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('.indication-menu').classList.remove('expanded');
  }, [location]);

  function toggleIndicationMenu() {
    const menu = document.querySelector('.indication-menu');
    menu.classList.toggle('expanded');
  }
  function intialActive(item) {
    const body = document.body.classList;
    return body.contains(item) ? 'active' : '';
  }

  function setActive(e) {
    document.querySelectorAll('.indication-menu li').forEach((item) => {
      item.classList.remove('active');
    });
    e.target.parentElement.classList.add('active');
    toggleIndicationMenu();
  }

  return (
    <div className="indication-menu">
      <button className="toggle-btn" onClick={toggleIndicationMenu}>
        Toggle Menu
      </button>
      <ul>
        <li className={intialActive('nsclc')}>
          <Link to="/n/" className="nsclc" onClick={setActive}>
            NSCLC
          </Link>
        </li>
        <li className={intialActive('crc')}>
          <Link to="/c/" className="crc" onClick={setActive}>
            OTHER
          </Link>
        </li>
      </ul>
      <p>Change Indication</p>
    </div>
  );
}
