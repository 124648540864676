// React Imports
import React, { useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";

// Import Components
import Hero from "../../components/Hero";
// import PurpleBanner from "../../components/PurpleBanner";
import { Helmet } from "react-helmet";
import AnchorScroll from "../../components/AnchorScroll";

// Import Images
import fourtyThreePercent from "../../images/KRAZATI_PAT_WEB_ILLI_43_Percent_Caption.jpg";
import eightyPercent from "../../images/KRAZATI_PAT_WEB_ILLI_80_Percent_Caption.jpg";
import durationCalendar from "../../images/Krazati_PAT_WEB_ILLI8_months.jpg";
import chartWithHandM from "../../images/efficacy_hand_chart-m.png";
import chartWithHand from "../../images/efficacy_hand_chart.png";
// import chart from "../../images/efficacy-chart.png";

export default function NClinicalTrialResults() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("study-results", "nsclc");
  }, []);

  // const openModal = useOutletContext();

  return (
    <>
      <Helmet>
        <title>KRAZATI&reg; (adagrasib) | Clinical Trial Results</title>
        <meta
          name="description"
          content="Learn how KRAZATI® (adagrasib) worked for patients in the clinical trial. See study results."
        />
      </Helmet>
      <AnchorScroll />
      <Hero title="Clinical" subtitle="Trial Results" />
      <section>
        <h3>
          KRAZATI was tested in a clinical trial of 116 adults with advanced
          NSCLC.
        </h3>
        <p>
          The trial evaluated how many patients responded to KRAZATI, meaning
          their tumors shrank or disappeared, as well as how long the tumor
          responded to KRAZATI.
        </p>
      </section>
      <section className="flex-container img-right">
        <div className="flex-item">
          <h3 className="mobile-only">
            43% saw tumors shrink or disappear.{" "}
            <span>(Objective response rate)</span>
          </h3>
          <img
            src={fourtyThreePercent}
            alt="Percent meter representing the ORR data in the KRAZATI - adagrasib clinical trial results"
          />
        </div>
        <div className="flex-item">
          <h3 className="desktop-only">
            43% saw tumors shrink or disappear.{" "}
            <span>(Objective response rate)</span>
          </h3>
          <p>
            Based on 112 people, of whom 1 person saw all signs of cancer
            disappear, and 47 people who saw a decrease in tumor size.
          </p>
        </div>
      </section>
      {/* <section className="flex-container img-left">
        <div className="flex-item">
          <h3 className="mobile-only">
            80% had tumors shrink or stay the same&nbsp;size.{" "}
            <span>(Disease control rate)</span>
          </h3>
          <img
            src={eightyPercent}
            alt="Percent meter representing the response data (disease control rate) in the KRAZATI - adagrasib clinical trial results"
          />
        </div>
        <div className="flex-item">
          <h3 className="desktop-only">
            80% had tumors shrink or stay the same&nbsp;size.{" "}
            <span>(Disease control rate)</span>
          </h3>
          <p>
            Stabilized tumor growth means tumors did not grow by greater than
            20% or shrink by 30% or more.
            <br />
            <br />
            Based on 112 people, 1% of people saw all signs of cancer disappear,
            42% saw a decrease in tumor size, and 37% saw their tumor remain
            stable.
          </p>
        </div>
      </section> */}
      <section className="flex-container img-right npb">
        <div className="flex-item">
          <h3 className="mobile-only">
            Duration of response to treatment was 8.5 months.
          </h3>
          <img
            src={durationCalendar}
            alt="Chart representing the median duration of response to treatment in KRAZATI - adagrasib clinical trial results"
          />
          <p className="footnotes desktop-only">
            NSCLC=non-small cell lung cancer.
          </p>
        </div>
        <div className="flex-item">
          <h3 className="desktop-only">
            Duration of response to treatment was 8.5 months.
          </h3>
          <p>
            Of those people whose tumor responded to treatment, the duration of
            response was 8.5 months (median) with KRAZATI.
          </p>
          <p className="footnotes mobile-only">
            NSCLC=non-small cell lung cancer.
          </p>
        </div>
      </section>
      <section>
        <h3 className="mbp">Possible side effects</h3>
        <p className="mbp">
          At this point in your NSCLC journey, you are priority number one.
          That’s why it is important to talk to your healthcare provider right
          away if you experience any side effects.
        </p>
        <p className="blue-text">
          KRAZATI may cause serious side effects, including stomach and
          intestinal (gastrointestinal) problems, changes in the electrical
          activity of your heart called QTc prolongation, liver problems, and
          lung or breathing problems.
        </p>
      </section>
      <section>
        <p>
          <strong>Stomach and intestinal (gastrointestinal) problems</strong>
        </p>
        <p className="mbp">
          Stomach and intestinal side effects including nausea, diarrhea, or
          vomiting, are common with KRAZATI but can also sometimes be severe.
          KRAZATI can also cause serious stomach and intestinal side effects
          such as bleeding, obstruction, inflammation of the colon (colitis),
          and narrowing (stenosis).
        </p>
        <p>
          <strong>
            Call your healthcare provider if you develop any of the signs or
            symptoms of stomach or intestinal problems listed above during
            treatment with KRAZATI.
          </strong>{" "}
          Your healthcare provider may prescribe an antidiarrheal medicine or
          anti-nausea medicine, or other treatment, as needed.
        </p>
      </section>
      <section>
        <p>
          <strong>
            Changes in the electrical activity of your heart (called QTc
            prolongation)
          </strong>
        </p>
        <p>
          Certain changes can occur in the electrical activity of your heart
          during treatment with KRAZATI, and can be seen on a test called an
          electrocardiogram (ECG or EKG). QTc prolongation can increase your
          risk for irregular heartbeats that can be life-threatening, such as
          torsades de pointes, and can lead to sudden death.
        </p>
        <ul>
          <li>
            You should not take KRAZATI if you have congenital long QT syndrome
            or if you currently have QTc prolongation
          </li>
          <ul>
            <li>
              Your healthcare provider should monitor the electrical activity of
              your heart and the levels of body salts in your blood
              (electrolytes) during treatment with KRAZATI if you have heart
              failure, a slow heart rate, abnormal levels of electrolytes in
              your blood, or if you take a medicine that can prolong the QT
              interval of your heartbeat
            </li>
            <li>
              <strong>
                Tell your healthcare provider if you feel dizzy, lightheaded, or
                faint, or if you get abnormal heartbeats during treatment with
                KRAZATI
              </strong>
            </li>
          </ul>
        </ul>
      </section>
      <section>
        <p>
          <strong>Liver problems</strong>
        </p>
        <p>
          Abnormal liver blood test results are common with KRAZATI and can
          sometimes be severe. Your healthcare provider should do blood tests
          before starting and during treatment with KRAZATI to check your liver
          function. Tell your healthcare provider right away if you develop any
          signs or symptoms of liver problems, including:
        </p>
        <ul className="liver-problems">
          <li>
            Your skin or the white part of your eyes turns yellow (jaundice)
          </li>
          <li>Dark or “tea-colored” urine</li>
          <li>Light-colored stools (bowel movements)</li>
          <li>Tiredness or weakness</li>
          <li>Nausea or vomiting</li>
          <li>Bleeding or bruising</li>
          <li>Loss of appetite</li>
          <li>
            Pain, aching, or tenderness on the right side of your stomach area
            (abdomen)
          </li>
        </ul>
      </section>
      <section className="hand-chart-section">
        <p>
          <strong>Lung or breathing problems</strong>
        </p>
        <p className="mbp">
          KRAZATI may cause inflammation of the lungs that can lead to death.
          Tell your healthcare provider or get emergency medical help right away
          if you have new or worsening shortness of breath, cough, or fever.
        </p>
        <p className="mbp">
          <span className="blue">
            The most common side effects of KRAZATI include:
          </span>{" "}
          nausea, diarrhea, vomiting, tiredness, muscle and bone pain, kidney
          problems, swelling, breathing trouble, and decreased appetite.
        </p>
        <p className="mbp">
          Certain abnormal laboratory test results are common with KRAZATI. Your
          healthcare provider will monitor you for abnormal laboratory tests and
          treat you if needed.
        </p>
        <p className="mbp">
          KRAZATI may cause fertility problems in males and females, which may
          affect your ability to have children. Talk to your healthcare provider
          if this is a concern for you.
        </p>
        <h3 className="mtp">Most common side effects of KRAZATI (N=116)</h3>
      </section>
      <div className="side-effects-chart-container">
        <img
          className="chart hand mobile-only"
          src={chartWithHandM}
          alt="Woman's arm holding chart representing the most common side effects of KRAZATI - adagrasib"
        />
        <img
          className="chart hand desktop-only"
          src={chartWithHand}
          alt="Woman's arm holding chart representing the most common side effects of KRAZATI - adagrasib"
        />
      </div>
      <section>
        <p className="mbp">
          <span>
            These are not all the possible side effects of KRAZATI. Call your
            doctor for medical advice about side effects.
          </span>{" "}
          You may report side effects to the FDA at{" "}
          <a className="no-style" href="tel:+18003321088">
            <span className="no-wrap">1-800-FDA-1088</span>
          </a>
          . You may also report side effects to Mirati at{" "}
          <a className="no-style" href="tel:+18446472841">
            <span className="no-wrap">1-844-MIRATI-1</span>
          </a>
          .
        </p>
      </section>
      <section className="purple-banner">
        <div className="content-wrapper">
          <p>
            KRAZATI may provide a response to{" "}
            <em className="not-bold">KRAS&nbsp;G12C</em>.
          </p>{" "}
          <Link to="/n/taking-krazati">
            <em>HOW TO TAKE KRAZATI&nbsp;&gt;</em>
          </Link>
        </div>
      </section>
    </>
  );
}
