import React, { useEffect } from "react";

// Img Imports
import down from "../images/down_arrow.svg";

const Hero = ({ title, subtitle }) => {
  //   useEffect(() => {
  //     window.addEventListener("scroll", setStickyButton);
  //     window.addEventListener("resize", setStickyButton);
  //     setStickyButton();
  //   }, []);

  // function setStickyButton() {
  //   // Variables
  //   const section = document.querySelector("section.hero + section");
  //   const position = section.getBoundingClientRect();
  //   const element = document.querySelector(".scroll-down");

  //   if (position.top < window.innerHeight + 200 && window.innerWidth < 1024) {
  //     element.classList.add("sticky");
  //   } else {
  //     element.classList.remove("sticky");
  //   }

  //   if (position.top < window.innerHeight - 85 && window.innerWidth < 1024) {
  //     element.classList.add("bottom");
  //   } else {
  //     element.classList.remove("bottom");
  //   }
  // }

  function scrollDown() {
    const targetSection = document.querySelector(".hero + section");
    let topPos = targetSection.offsetTop;
    if (window.innerWidth < 1024) {
      topPos = targetSection.offsetTop - 58;
    }

    window.scroll({
      top: topPos,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <section className="hero">
      <div className="hero-wrapper">
        <h1>
          {title && <div>{title}</div>}
          {subtitle && <span>{subtitle}</span>}
        </h1>
      </div>
      <p className="footnote">Not an actual patient.</p>
      <div className="scroll-down sticky bottom" onClick={scrollDown}>
        <img src={down} alt="down" />
      </div>
    </section>
  );
};

export default Hero;
