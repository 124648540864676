// React Imports
import React, { useEffect } from "react";

export default function AnchorScroll() {
  useEffect(() => {
    setDefaultScrollPosition();
    setListeners();
    setTimeout(() => {
      checkForID();
    }, 400);
  }, []);

  // set the scroll position to the top
  // upon initial navigation
  function setDefaultScrollPosition() {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    })
  }

  function setListeners() {
    document.querySelectorAll('a[href^="#"]').forEach((a) => {
      a.addEventListener("click", function (e) {
        e.preventDefault();
        const href = a.getAttribute("href");
        const elem =
          document.querySelector(href) ||
          document.querySelector(
            "a[name=" + href.substring(1, href.length) + "]"
          );

        const position = elem.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: position,
          left: 0,
          behavior: "smooth",
        });
      });
    });
  }

  function checkForID() {
    const href = window.location.hash;
    if (href) {
      const elem = document.querySelector(href);
      if (elem) {
        const position = elem.getBoundingClientRect().top + window.pageYOffset;
        if (window.innerWidth < 1024) {
          window.scrollTo({
            top: position - 58,
            left: 0,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: position,
            left: 0,
            behavior: "smooth",
          });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: "instant",
      // });
    }
  }

  return <></>;
}
