// React Imports
import React, { useState } from 'react';

// Import Images
import x from '../images/icon_close.svg';

export default function Violator(props) {
  const [expanded, expandViolator] = useState(0);
  let violatorOpen = props.violator;

  function toggleViolator() {
    // Variables
    const violator = document.getElementById('violator');

    if (violator.classList.contains('expanded')) {
      // expand violator
      violator.classList.remove('expanded');
      expandViolator(0);
    } else {
      // collapse violator
      violator.classList.add('expanded');
      expandViolator(1);
    }
  }

  return (
    <>
      {violatorOpen ? (
        <div id="violator" className="violator">
          <img
            className="x show"
            src={x}
            alt="x"
            onClick={() => {
              props.closeViolator();
            }}
          />
          <h4>What is KRAZATI?</h4>
          <p className="more" onClick={toggleViolator}>
            {expanded ? 'LESS' : 'MORE'}
          </p>
          <p className="landing">
            KRAZATI is a prescription medicine used in adults alone to treat
            non-small cell lung cancer (NSCLC) that has spread to other parts of
            the body or cannot be removed by surgery, and whose tumor has an
            abnormal KRAS G12C gene, and who have received at least one prior
            treatment.
            <br></br>
            KRAZATI is a prescription medicine used in adults in combination
            with a medicine called cetuximab to treat colon or rectal cancer
            (CRC) that has spread to other parts of the body or cannot be
            removed by surgery, and whose tumor has an abnormal KRAS G12C gene,
            and who have previously received certain chemotherapy medicines.
            <br></br>
            Your healthcare provider will perform a test to make sure that
            KRAZATI is right for you. It is not known if KRAZATI is safe and
            effective in children.
          </p>
          <p className="crc">
            KRAZATI is a prescription medicine used in adults in combination
            with a medicine called cetuximab to treat colon or rectal cancer
            (CRC) that has spread to other parts of the body or cannot be
            removed by surgery, and whose tumor has an abnormal KRAS G12C gene,
            and who have previously received certain chemotherapy medicines.
            <br></br>
            Your healthcare provider will perform a test to make sure that
            KRAZATI is right for you. It is not known if KRAZATI is safe and
            effective in children.
          </p>
          <p className="nsclc">
            KRAZATI is a prescription medicine used to treat adults with
            non-small cell lung cancer (NSCLC) that has spread to other parts of
            the body or cannot be removed by surgery, and whose tumor has an
            abnormal KRAS G12C gene, and who have received at least one prior
            treatment for their cancer.<br></br> Your healthcare provider will
            perform a test to make sure that KRAZATI is right for you. It is not
            known if KRAZATI is safe and effective in children.
          </p>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
