// React Imports
import React from "react";

// Library Imports
import ReactModal from "react-modal";

// Image Imports
import x from "../images/icon_close.svg";
import rotateGraphic from "../images/rotate-graphic.jpg";

export default function RotateModal(props) {
  // const [customValue, setCustomValue] = useState("");

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.modal === 1}
      contentLabel="Rotate Modal"
      className="rotate-modal"
    >
      <img
        className="x show"
        src={x}
        alt="x"
        onClick={() => {
          props.closeModal();
        }}
      />

      <div className="flex-container">
        <img src={rotateGraphic} alt="rotateGraphic" />
        <div className="text-container">
          <p className="first">rotate</p>
          <p className="second">
            your phone for
            <br />
            the best experience
          </p>
        </div>
      </div>
    </ReactModal>
  );
}
