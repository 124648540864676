// React Imports
import React, { useEffect } from 'react';
// import { useOutletContext } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnchorScroll from '../../components/AnchorScroll';
// Import Components
import Hero from '../../components/Hero';

export default function CReferences() {
  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('references', 'crc');
  }, []);

  // const openModal = useOutletContext();

  return (
    <>
      <Helmet>
        <title>Advanced Disease Treatment & Research References</title>
        <meta
          name="description"
          content="Find treatment options, research, and scientific literature with comprehensive references."
        />
      </Helmet>
      <AnchorScroll />
      <Hero subtitle="References" />

      <section>
        <ol>
          <li>
            KRAZATI®. Prescribing information. Princeton, NJ. Mirati Therapeutics, Inc., a Bristol Myers Squibb company; 2024.
          </li>
          <li>
            Colorectal cancer. Medline Plus. Updated March 5, 2024. Accessed
            April 11, 2024.{' '}
            <span className="word-wrap">
              https://medlineplus.gov/colorectalcancer.html
            </span>
          </li>
          <li>
            NCI dictionary of cancer terms. Definition of mutation. National
            Cancer Institute. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/publications/dictionaries/cancer-terms/def/mutation
            </span>
          </li>
          <li>
            The genetics of cancer. American Society of Clinical Oncology.
            Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.net/navigating-cancer-care/cancer-basics/genetics/genetics-cancer
            </span>
          </li>
          <li>
            Cox AD, Fesik SW, Kimmelman AC, Luo J, Der CJ. Drugging the
            undruggable Ras: mission possible? <em>Nat Rev Drug Discov.</em>{' '}
            2014;13(11):828-851.
          </li>
          <li>
            Fernández-Medarde A, Santos E. Ras in cancer and developmental
            diseases. <em>Genes Cancer.</em> 2011;2(3):344-358.
          </li>
          <li>
            Haigis KM. KRAS alleles: the devil is in the detail.{' '}
            <em>Trends Cancer.</em>{' '}
            2017;3(10):686-697.
          </li>
          <li>
            Meng M, Zhong K, Jiang T, Liu Z, Kwan HY, Su T. The current
            understanding on the impact of KRAS on colorectal cancer.{' '}
            <em>Biomed Pharmacother.</em> 2021:140:111717.
          </li>
          <li>
            Nassar AH, Adib E, Kwiatkowski DJ. Distribution of{' '}
            <em>
              KRAS<sup>G12C</sup>
            </em>{' '}
            somatic mutations across race, sex, and cancer type.
            <em> N Engl J Med.</em>{' '}
            2021;384(2):185-187.
          </li>
          <li>
            NCI dictionary of cancer terms. Definition of wild-type KRAS gene.
            National Cancer Institute. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/publications/dictionaries/cancer-terms/def/wild-type-kras-gene
            </span>
          </li>
          <li>
            Biomarker testing for cancer treatment. National Cancer Institute.
            Updated December 14, 2021. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment
            </span>
          </li>
          <li>
            Dey A, Mitra A, Pathak S, et al. Recent advancements, limitations,
            and future perspectives of the use of personalized medicine in
            treatment of colon cancer. <em>Technol Cancer Redd Treat.</em>{' '}
            2023;22:153303338231178403.
          </li>
          <li>
            Diarrhea: cancer treatment side effect. National Cancer Institute.
            Updated September 24, 2021. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/diarrhea
            </span>
          </li>
          <li>
            Nausea and vomiting. National Cancer Institute. Updated October 14,
            2021. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/nausea-vomiting
            </span>
          </li>
          <li>
            Cancer fatigue. National Cancer Institute. Updated September 23,
            2021. Accessed April 12, 2024.{' '}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/fatigue
            </span>
          </li>
        </ol>
      </section>
    </>
  );
}
