// React Imports
import React, { useEffect } from "react";
// import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorScroll from "../../components/AnchorScroll";
// Import Components
import Hero from "../../components/Hero";

export default function NReferences() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("references", "nsclc");
  }, []);

  // const openModal = useOutletContext();

  return (
    <>
      <Helmet>
        <title>KRAZATI&reg; (adagrasib) References</title>
        <meta
          name="description"
          content="Learn about the science and research behind KRAZATI&reg; (adagrasib) and more with these additional resources."
        />
      </Helmet>
      <AnchorScroll />
      <Hero subtitle="References" />

      <section>
        <ol>
          <li>
            KRAZATI. Prescribing Information. Mirati Therapeutics, Inc.; 2022.
          </li>
          <li>
            Lung cancer. Medline Plus. Updated December 1, 2017. Accessed August
            24, 2022.{" "}
            <span className="word-wrap">
              https://www.medlineplus.gov/genetics/condition/lung-cancer/
            </span>
          </li>
          <li>
            NCI dictionary of cancer terms. National Cancer Institute. Accessed
            August 24, 2022.{" "}
            <span className="word-wrap">
              https://www.cancer.gov/publications/dictionaries/cancer-terms/def/mutation
            </span>
          </li>
          <li>
            The genetics of cancer. American Society of Clinical Oncology.
            Accessed August 24, 2022.{" "}
            <span className="word-wrap">
              https://www.cancer.net/navigating-cancer-care/cancer-basics/genetics/genetics-cancer
            </span>
          </li>
          <li>
            Cox AD, Fesik SW, Kimmelman AC, Luo J, Der CJ. Drugging the
            undruggable Ras: mission possible? <em>Nat Rev Drug Discov</em>.
            2014;13(11):828-851.
          </li>
          <li>
            Haigis KM. KRAS alleles: the devil is in the detail.{" "}
            <em>Trends Cancer</em>. 2017;3(10):686-697.
          </li>
          <li>
            Nassar AH, Adib E, Kwiatkowski DJ. Distribution of KRAS
            <sup>G12C</sup> somatic mutations across race, sex, and cancer type.{" "}
            <em>N Engl J Med</em>. 2021;384(2):185-187.
          </li>
          <li>
            Fernández-Medarde A, Santos E. Ras in cancer and developmental
            diseases. <em>Genes Cancer</em>. 2011;2(3):344-358.
          </li>
          <li>
            Definition of wild-type KRAS gene. National Cancer Institute.
            Accessed August 24, 2022.
            <span className="word-wrap">
              https://www.cancer.gov/publications/dictionaries/cancer-terms/def/wild-type-kras-gene
            </span>
          </li>
          <li>
            Biomarker testing for cancer treatment. National Cancer Institute.
            Updated December 14, 2021. Accessed August 24, 2022.{" "}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/types/biomarker-testing-cancer-treatment
            </span>
          </li>
          <li>
            Pakkala S, Ramalingam SS. Personalized therapy for lung cancer:
            striking a moving target. <em>JCI Insight</em>. 2018;3(15):e120858.
          </li>
          <li>
            Jänne PA, Riely GJ, Gadgeel SM, et al. Adagrasib in non–small-cell
            lung cancer harboring a <em>KRASG12C</em> mutation.{" "}
            <em>N Engl J Med</em>. 2022;387(2):120-131.
          </li>
          <li>
            Eisenhauer EA, Therasse P, Bogaerts J, et al. New response
            evaluation criteria in solid tumours: revised RECIST guideline
            (version 1.1). <em>Eur J Cancer</em>. 2009;45(2):228-247.
          </li>
          <li>
            Diarrhea: cancer treatment side effect. National Cancer Institute.
            Updated September 24, 2021. Accessed November 16, 2021.{" "}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/diarrhea
            </span>
          </li>
          <li>
            Nausea and vomiting in people with cancer. National Cancer
            Institute. Updated October 14, 2021. Accessed August 24, 2022.{" "}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/nausea-vomiting
            </span>
          </li>
          <li>
            Fatigue and cancer treatment. National Cancer Institute. Updated
            September 23, 2021. Accessed August 24, 2022.{" "}
            <span className="word-wrap">
              https://www.cancer.gov/about-cancer/treatment/side-effects/fatigue
            </span>
          </li>
        </ol>
      </section>
    </>
  );
}
