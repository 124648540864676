// React Imports
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnchorScroll from "../../components/AnchorScroll";

// Img Imports
import down from "../../images/down_arrow.svg";
import handQuotation from "../../images/hand_quotation_marks.png";
import quotes from "../../images/quotes.png";
import graphicM from "../../images/homepage-graphic-m.png";
import graphicD from "../../images/homepage-graphic-d.png";

export default function NHome() {
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("home", "nsclc");

    // window.addEventListener("scroll", function () {
    //   parallax();
    // });
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [handleOpenModal, handleOpenFormModal] = useOutletContext();

  function scrollDown() {
    const targetSection = document.querySelector(".hero + section");
    let topPos;

    if (window.innerWidth < 1024) {
      topPos = targetSection.offsetTop + 150;
    } else {
      topPos = targetSection.offsetTop + 350;
    }

    window.scroll({
      top: topPos,
      left: 0,
      behavior: "smooth",
    });
  }

  // function parallax() {
  //   const a = document.getElementById("floater");
  //   let yPos = window.pageYOffset / 20;
  //   if (window.innerWidth < 1024) {
  //     a.style.top = "0%";
  //   } else {
  //     a.style.top = yPos + "%";
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>Treatment Options for Advanced Disease | Patient Information</title>
        <meta
          name="description"
          content="Review therapy options and innovative approaches for managing advanced disease. Learn about effective treatment options and breakthroughs in patient care."
        />
      </Helmet>
      <AnchorScroll />
      <section id="phero" className="hero">
        <div id="floater" className="hero-wrapper">
          <h1>
            <span className="small">
              In <em>KRAS&nbsp;G12C</em>-positive advanced NSCLC
            </span>
            This is my non-small
            <span className="large green">revolution</span>
          </h1>
          <p>
            Focusing on yourself is your boldest move yet. Partnering with your
            care team and experiencing KRAZATI—an oral, targeted therapy—can
            keep the focus on you and your NSCLC.
          </p>

        </div>
        <p className="footnote">Not an actual patient.</p>
      </section>
      <section className="blue">
        <div className="content-wrapper">
          <div className="scroll-down dt" onClick={scrollDown}>
            <img src={down} alt="down" />
          </div>
          <div className="color-ctas">
            <Link
              to="/n/about-biomarkers#biomarker-testing"
              className="cta purple"
            >
              <p className="cta-header">HAVE YOU HAD A BIOMARKER TEST?</p>
              <p className="footer">
                Learn why it&rsquo;s{" "}
                <span className="last-word">essential</span>
              </p>
            </Link>
            <Link to="/n/support" className="cta green">
              <p className="cta-header">
                RESOURCES
                <br />
                DESIGNED FOR YOU
              </p>
              <p className="footer">
                Get personalized <span className="last-word">help</span>
              </p>
            </Link>
            <Link to="/n/study-results" className="cta pink">
              <p className="cta-header">THE KRAZATI EXPERIENCE</p>
              <p className="footer">
                See clinical trial <span className="last-word">results</span>
              </p>
            </Link>
          </div>
          <div className="quote">
            <img
              className="quotes hands"
              src={handQuotation}
              alt="An arm is holding up a green quote mark to indicate a patient testimonial about Mirati"
            />
            <img
              className="quotes no-hands"
              src={quotes}
              alt="Green quotation mark surrounding KRAZATI - adagrasib quote"
            />
            <p>
              Mirati and their commitment to their patients is what gives me the
              hope of one more&nbsp;day.”
              <span className="name">KRAZATI TRIAL PARTICIPANT</span>
            </p>
            <img
              className="graphic mobile-only"
              src={graphicM}
              alt="KRAZATI - adagrasib logo"
            />
            <img
              className="graphic desktop-only"
              src={graphicD}
              alt="KRAZATI - adagrasib 200 mg tablets"
            />
          </div>
          <p className="footnotes white">NSCLC=non-small cell lung cancer.</p>
        </div>
      </section>
    </>
  );
}
